import React, {useContext, useMemo, useState} from 'react';
import {Container, LightContainer, Loader, MaxBtn} from "../core/components/components";
import {MyModal} from "../core/components/modal";
import {CheckboxInput, TextareaInput} from "../core/input/basic";
import {SelectfieldInput} from "../core/input/select";
import "./course_form.css"
import Status from "../core/status";
import {apiGet, apiPost} from "../core/api";
import {UserContext} from "../user/UserContext";
import EventsAdd from "../calendar/events_add";
import {dateFormatTime, timeFormat} from "../core/dateFuncs";
import {Link} from "react-router-dom";
import {FormContext, FormContextWrapper} from "../core/form_context";
import UserConfig from "../user/user_config";

import {maxiGet, maxiPost} from "../core/maxios";
import Consts from "../core/consts";
import {checkPermissions} from "../club/permissionsHelpers";
import {MyReactTable} from "../core/components/table";
import {FaEdit} from "react-icons/fa";
import {FaTrashAlt} from "react-icons/all";

class CourseEvents extends React.Component {


    constructor(props) {
        super(props);
        this.id = props.id;
        this.apiGet = apiGet.bind(this);
        this.apiPost = apiPost.bind(this);
        this.setState = this.setState.bind(this)
        this.loadEvents()
        this.config = new UserConfig()
        this.state = {
            error: "",
            events: [],
            loading: true,
            show: {
                previous: this.config.get("showPreviousEvents"),
            }
        };
    }

    loadEvents = () => {
        const timeNow = (new Date()).getTime() / 1000;
        this.apiGet("/course/events/" + this.id, resp => {
            let stopDates = {}
            const newEvents = resp.events.map(e => {
                const id = e.previous_ID === 0 ? e.ID : e.previous_ID;
                let stopDate = stopDates[id];
                if (stopDate === undefined) {
                    stopDate = resp.events.filter(a => a.previous_ID === id).reduce((v, e) => e.end > v ? e.end : v, e.end)
                    stopDates[id] = stopDate;
                }

                return {...e, stop: stopDate};
            });
            const anyAttendanceExist = resp.events.filter(e => e.end > timeNow || this.state.show.previous).some(a => a.attendance)
            this.setState({events: newEvents, loading: false, anyAttendanceExist})
        });
    };
    static contextType = UserContext;

    render() {
        const timeNow = (new Date()).getTime() / 1000;

        const {show, events} = this.state;
        return <>
            <Status type="error" text={this.state.error}/>
            <Loader loading={this.state.loading}/>


            <FormContextWrapper value={{state: this.state, setState: this.setState}} afterUpdate={(a, b, c) => {
                if (a === "show" && b === "previous") {
                    this.config.save({showPreviousEvents: c.previous})
                }
            }}>
                <div style={{float: window.innerWidth > 700 ? "right" : "inherit"}}>
                    <CheckboxInput name={""} noLabel tag={"show_previous"}/> vergangene anzeigen
                </div>
            </FormContextWrapper>

            {
                this.props.showAddSickNote && this.context.user?.verein_ID !== 3 && <AddSickNote
                    course_ID={this.id}
                    events={events
                        .sort((a, b) => a.start - b.start)
                        .filter(e => e.end > timeNow)
                    }
                />
            }
            {
                events.length > 0 && <FormContext.Consumer>
                    {
                        context => <MyReactTable
                            title={"Termine von " + context.state.course.name}
                            exportData noCsv columnsConfigurable={false}
                            pageSizeDef={100}
                            data={events
                                .sort((a, b) => a.start - b.start)
                                .filter(e => e.end > timeNow || !!show.previous)}
                            columns={[
                                {
                                    Header: "Name",
                                    accessor: "name",
                                    Cell: ({original: e}) => <div style={{whiteSpace: "normal"}}> {
                                        (!!this.context.user && (
                                            this.context.user.role >= 80 || (
                                                checkPermissions(this.context, "course/events_read", this.id) && checkPermissions(this.context, "course/participants_read", this.id)
                                            )
                                        )) || (!!context.state.participant && context.state.participant.role === 1) ?
                                            <Link to={{pathname: `/kurs/${this.id}/anwesenheit/${e.ID}`, state: {event: e, course: context.state.course}}}>{e.name}</Link> :
                                            e.name
                                    }</div>
                                },
                                {
                                    Header: "Zeit",
                                    accessor: "start",
                                    pdfCell: ({original: e}) => dateFormatTime(e.start) + " - " + ((e.end - e.start) < 86400 ? timeFormat(e.end) : dateFormatTime(e.end)),
                                    Cell: ({original: e}) => {
                                        return <div style={{whiteSpace: "normal"}}>
                                            am {dateFormatTime(e.start)} bis&nbsp;
                                            {
                                                (e.end - e.start) < 86400 ?
                                                    timeFormat(e.end) :
                                                    dateFormatTime(e.end)
                                            }
                                            {
                                                e.start <= timeNow && timeNow <= e.end && <><br/>(gerade im Gange)</>
                                            }
                                        </div>
                                    }
                                },
                                {
                                    Header: "Ort",
                                    accessor: "room.label",
                                    Cell: ({value}) => <div style={{whiteSpace: "normal"}}>{value}</div>
                                },
                                {
                                    Header: "Anwesenheiten",
                                    show: events.some(event => (event.attendances || []).length > 0),
                                    maxWidth: 140,
                                    Cell: ({original: e}) => !!e.attendances && Array.isArray(e.attendances) ? e.attendances
                                        .filter(attendance => attendance?.status > 0)
                                        .map(attendance => attendance?.prename + ": " + (Consts.attendanceExprMap[attendance?.status] ?? "")).join(", ") : ""
                                },
                                {
                                    Header: "Anwesend",
                                    show: false,//this.state.anyAttendanceExist,
                                    maxWidth: 140,
                                    Cell: ({original: e}) => !!e.attendance ? (Consts.attendanceExprMap[e.attendance?.status] ?? "") : (e.end < timeNow ? "abwesend (autom)" : "")
                                },
                                //{ && <td>{e.attendance && }</td>}
                                ...(checkPermissions(this.context, "course/events", this.id) ? [
                                    {
                                        Header: "",
                                        id: "verändern",
                                        maxWidth: 30,
                                        pdfShow: false,
                                        Cell: ({original: e}) => <MyModal trigger={<em><FaEdit/></em>} modal>
                                            {close => <Container>
                                                <EventsAdd course_ID={this.id} ID={e.ID} event={e} addEvents={() => {
                                                    this.loadEvents()
                                                    close()
                                                }
                                                }/>
                                            </Container>
                                            }
                                        </MyModal>
                                    },
                                    {
                                        Header: "",
                                        id: "entfernen",
                                        pdfShow: false,
                                        maxWidth: 30,
                                        Cell: ({original: e}) => <em onClick={() => {
                                            this.apiPost("/events/hide/" + e.ID)
                                            this.setState({events: this.state.events.filter(a => a.ID !== e.ID)})
                                        }}>
                                            <FaTrashAlt/>
                                        </em>
                                    }, {
                                        Header: "Feiertag",
                                        pdfShow: false,
                                        id: "Feiertag",
                                        maxWidth: 135,
                                        show: events.some(a => a.holiday || a.school_holiday),
                                        Cell: ({original: e}) => <>{e.holiday ? <b>Feiertag</b> : (e.school_holiday ? <b>Schulfreier Tag</b> : "")}</>
                                    }

                                ] : [])
                            ]}
                        />}
                </FormContext.Consumer>
            }
            {
                checkPermissions(this.context, "course/events", this.id) && <>
                    <hr/>
                    <h2>Termin hinzufügen</h2>
                    <EventsAdd course_ID={this.id} addEvents={this.loadEvents}/>
                </>
            }

        </>

    }
}

function AddSickNote({events, course_ID}) {
    const {user} = useContext(UserContext);
    const [minimized, setMinimized] = useState(true);
    const [state, setState] = useState({events: {note: ""}})
    const [{error, success, loading}, setStatusVar] = useState({loading: false});
    const [subusers, setSubusers] = useState([]);
    const [sickNoteType, setSickNoteType] = useState("sick");

    const allowToPreregister = ![Consts.CLUB_ITV_INNSBRUCK, Consts.CLUB_OETB_LINZ].includes(user.verein_ID)

    useMemo(() => {
        if (!minimized) {
            maxiGet("/course/participate/" + course_ID, {setStatusVar}).then(resp => {
                const childs = resp.children.filter(({participating}) => participating && participating.timeLeave === 0).map(({child}) => child)
                setSubusers(childs)
                if (childs.length === 1) {
                    setState({events: {who: (childs[0] || {}).ID}})
                }
            });
        }
    }, [minimized])

    if (minimized) {
        return <MaxBtn onClick={() => setMinimized(false)}>Für Termine entschuldigen{allowToPreregister && " / als anwesend markieren"}</MaxBtn>
    }
    const handleSubmission = e => {
        e.preventDefault();
        if (loading){
            return
        }
        maxiPost("/course/attendances/addSickNote?type=" + sickNoteType, state.events, {setStatusVar}).then(() => {
            setStatusVar({success: sickNoteType === "sick" ? "Deine Entschuldigung wurde erfolgreich gespeichert." : "Deine Anwesenheit an diesen Terminen wurde eingetragen."})
        })
    }
    return <LightContainer name={"Für folgende Termine entschuldigen / als anwesend eintragen"}>
        <Status type={"error"} text={error}/>
        <Status type={"success"} text={success}/>
        {
            !success &&
            <FormContextWrapper value={{state, setState}} onSubmit={handleSubmission}>
                <p>{
                    subusers.length > 1 ?
                        <>Ich möchte<br/><SelectfieldInput tag={"events_who"} name={"Person auswählen"} demandSelect selectives={subusers.map(child => [child.ID, child.fullname])}/><br/></> :
                        <>Ich möchte mich</>
                } </p>
                <span><input type={"radio"} checked={sickNoteType === "sick"} name={"sickNoteType"} onChange={(e) => e.target.checked && setSickNoteType("sick")}/> entschuldigen</span><br/>
                {
                    allowToPreregister && <span><input type={"radio"} checked={sickNoteType === "active"} name={"sickNoteType"} onChange={(e) => e.target.checked && setSickNoteType("active")}/> als anwesend eintragen</span>
                }
                <p>an den folgenden Terminen:</p>
                {
                    events.slice(0, sickNoteType === "active" ? 1 : 10).map(e => <div>
                            <input type={"checkbox"} checked={state.events[e.ID]} name={"events_" + e.ID}/>
                            {e.name},
                            am <b>
                            {dateFormatTime(e.start)} bis&nbsp;
                            {
                                (e.end - e.start) < 86400 ?
                                    timeFormat(e.end) :
                                    dateFormatTime(e.end)
                            }
                        </b>
                        </div>
                    )
                }
                <p></p>


                <TextareaInput name={"Anmerkung hinzufügen"} tag={"events_note"}/>
                <MaxBtn>Abschicken</MaxBtn>
            </FormContextWrapper>
        }
        <br/>
        <hr/>
        <br/>

    </LightContainer>
}

export default CourseEvents;
